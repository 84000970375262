
@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700,900&display=swap');



body {
  padding: 0;
  margin: 0;
  font-family: 'Raleway', sans-serif;
}

.btn, .swal2-confirm {
  border-radius: 2em !important;
  padding: 0.7em 1.2em !important;
  border-width: 2px !important;
  font-weight: 600 !important;
}

.manutencao {
  color: #fff;
}

.manutencao h1 {
  font-weight: 600;
  font-size: 40px;
}

.slider {
  max-width: 800px;
}




a:hover {
  text-decoration: none;
}

.carousel-item {
    height: 60vh;
    min-height: 350px;
    background: no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: auto 100%;
  }

  .carousel-item img {
    height: 100vh;
  }

  .carousel-item:after {
    content:"";
    display:block;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:rgba(0,0,0,0.8);
  }

  .carousel-caption {
    top: 50%;
transform: translateY(-50%);
bottom: initial;
  }

  .navbar-toggler:focus, .navbar-toggler:active {
    outline: none;
    box-shadow: none;
  }



  @media (min-width: 320px) and (max-width: 480px) {
  
    .carousel-item {
      height: 90vh;
    }

    .navbar, .navbar .form-inline .botao, .navbar .brand  {
      text-align: center;
    }

    
  }



.footer {
    padding: 100px;
    background-color: rgb(19, 19, 19);
    color: #fff;
}

.footer a {
  color: #999;
}

.title-gradient {
    font-weight: 900;
    background: -webkit-linear-gradient(#8f222d, #a32532);
    -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-size: 3em;
}

.cartao {
  background: linear-gradient(to top, rgba(122, 19, 19, 0.85), rgb(58, 12, 12)), url("https://images.unsplash.com/photo-1534438327276-14e5300c3a48?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80");
  color: #fff;
  background-size:cover ;
}

.card-img {
    width: 100%;
    object-fit: cover;
}

.card {
  line-height: 1.6em;
}

.p-200 {
  padding: 200px;
}

.py-200 {
  padding-top: 200px;
  padding-bottom: 200px;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.icon-rounded {
  background: -webkit-linear-gradient(#dc3545, #a32532);
  margin: 0 auto;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 30px;
}

.icon-social {
  background: -webkit-linear-gradient(#dc3545, #a32532);
  margin: 0 auto;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 30px;
}

.icon {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    font-size: 4.5rem;
}


.icon-social-icon {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    font-size: 1.2rem;
}

.footer ul {
  list-style-type: none;
  padding-left: -20px;
}

.banner {
  background: linear-gradient(to top, rgba(122, 19, 19, 0.85), rgb(58, 12, 12)),
  /* bottom, image */
  url("https://images.unsplash.com/photo-1520881363902-a0ff4e722963?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80");
  width: 100%;
  height: 300px;
  background-size: 100%;
}

.banner-tribuna {
  background: linear-gradient(to top, rgba(80, 77, 47, 0.7), rgba(0, 0, 0, 0.8)),
  /* bottom, image */
  url("https://images.unsplash.com/photo-1529243856184-fd5465488984?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2569&q=80") no-repeat;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 100px;
  background-position: center;

}

.banner h1 {
  text-align: center;
  font-weight: 900;
  font-size: 3em;
  color: #fff;
  text-transform: uppercase;
  padding-top: 80px;

}

.banner h3 {
  color: #fff;
  text-align: center;
  font-size: 1.5em;
  letter-spacing: 3px;
  padding: 10px;
}

:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: .75rem;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
  background-color: transparent;
 
 
}

.dashboard-sidebar {
  background-color: #222;
  height: 100vh;
  width: 100px;
}

.dashboard-sidebar a {
 color: #fff;
}

.dashboard-sidebar a:active {
  background-color: #222;
 }
 .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background: -webkit-linear-gradient(#8f222d, #a32532);
}

.documentos a{
color: #222;
font-weight: bold;
}

.dropdown-menu{
  background-color: #222!important;
  padding: 10px;
  border: none;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.dropdown .nav-link {color:#fff; text-decoration: none;}
.dropdown .dropdown-menu a{color: #fff; text-decoration: none;}
.dropdown .btn { color:#fff;}
.dropdown .btn:hover {color:#000;}
.dropdown .btn:active {color:#000;}
.dropdown .btn:focus {color:#000;}
.container .dropdown .dropdown-menu a:hover
{
  color: #fff;
  background-color: #8f222d;
  border-color: #fff;
}



/* LOGIN */

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}



.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input, .form-label-group input:enabled {
  height: auto;
  border-radius: 2rem;
  background-color: rgba(0,0,0,0.1);
  border: none;
  color: #fff;
}

.form-label-group>input,
.form-label-group>label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #fff;
 
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn-login {
  color: #8f222d;
  background-color: #fff;
  border: none;
}

.btn-login:hover {
  color: #721c24 !important;
}

.login {
  background: -webkit-linear-gradient(#721c24, #a32532);
  height: 100vh;;
}

.login a {
  color: #fff;
}

/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

#pageDropDown {
  display: none;
}

.pagination {
  float: right;
}

.imagemembro {
  height: 300px;
  object-fit:cover;
}


.product-title {
  text-align: left;
    font-weight: 900;
    font-size: 2.4em;
    text-transform: uppercase;
    padding-top: 20px;
}

.saber-mais {
  color: #fff;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #222;
  border-color: #222;
}

.encomendar-popup {
  padding: 35px;
}

.encomendar-popup-title {
  padding: 30px 35px;
}

.anuncio-casa {
  border: 5px solid #721c24;
  border-radius: 20px;
  padding: 10px;
}

.anuncio-casa h6 {
  color: #721c24;
  font-weight: bold;
}

.img-card {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}

.img-card-home {
  width: 100%;
  height: 30vh;
  object-fit: cover;
}

.pagina-content {
  height: 100
}